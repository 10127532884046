const linkResolver = doc => {
  // Pretty URLs for known types
  if (doc.type === 'development') return `/development/${doc.uid}`;
  if (doc.type === 'article') return `/news/${doc.uid}`;
  if (doc.type === 'homepage') return '/';
  // Fallback for other types, in case new custom types get created
  return `/${doc.uid}`;
};

export default linkResolver;
