// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-routes-home-js": () => import("./../src/routes/home.js" /* webpackChunkName: "component---src-routes-home-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-basic-page-js": () => import("./../src/templates/basic_page.js" /* webpackChunkName: "component---src-templates-basic-page-js" */),
  "component---src-templates-contact-js": () => import("./../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-development-js": () => import("./../src/templates/development.js" /* webpackChunkName: "component---src-templates-development-js" */),
  "component---src-templates-home-type-js": () => import("./../src/templates/home_type.js" /* webpackChunkName: "component---src-templates-home-type-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-search-js": () => import("./../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */)
}

